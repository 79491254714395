export enum EnumAppSettings {
  PRODUCTS_DISPLAYED = 'PRODUCTS_DISPLAYED',
  CSS_CHAT_ICON = 'CSS_CHAT_ICON',
  GAP_BETWEEN_ICON_CHAT_AND_SCROLL_TOP = 'GAP_BETWEEN_ICON_CHAT_AND_SCROLL_TOP',
  LIMIT_RETRY_GET_TAX = 'LIMIT_RETRY_GET_TAX',
}

export enum EnumProductsDisplayedMobile {
  'HOME_CHEAPEST_PRODUCTS' = 'HOME_CHEAPEST_PRODUCTS',
  'HOME_NEW_PRODUCTS' = 'HOME_NEW_PRODUCTS',
  'PRD_DETAIL_RECOMMENDATION_PRODUCTS' = 'PRD_DETAIL_RECOMMENDATION_PRODUCTS',
  'PRD_DETAIL_SELLER_PRODUCTS' = 'PRD_DETAIL_SELLER_PRODUCTS',
  'PRD_DETAIL_INGREDIENT_PRODUCTS' = 'PRD_DETAIL_INGREDIENT_PRODUCTS',
  'PROMOTION_CAMPAIGN_PRODUCTS' = 'PROMOTION_CAMPAIGN_PRODUCTS',
}

