import { POST } from './Clients';

const PROMOTION_API_PREFIX = '/marketplace/promotion/v1';

async function getPromoLists({ skus, getVoucherInfo = false, signal }) {
  return POST({
    url: `${PROMOTION_API_PREFIX}/voucher/list-by-sku`,
    body: {
      skus,
      getVoucherInfo,
    },
    signal,
  });
}

export default {
  getPromoLists,
};

