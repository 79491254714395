export enum EnumBlockCodeTracking {
	HOME_MEGA_BLOCK = 'home_mega_block:<blockName>',
	HOME_KE_DON_GIA_TOT = 'home_ke_don_gia_tot',
	HOME_NEW_PRODUCTS = 'home_new-products',
	HOME_BEST_SELLER_PRODUCTS = 'home_best_seller_products',
	HOME_PROMOTION_PRODUCTS = 'home_promotions_products',
	HOME_JFY = 'home_jfy',
	HOME_RCM = 'home_recommendation_products',
	PRODUCT_LIST_SKU_NOT_BUY_RECENTLY = 'product-list_SKU_NOT_BUY_RECENTLY',
	PRODUCT_LIST_DEFAULT_LIST = 'product-list_default-list',
	PRODUCT_LIST_SEARCH_RESULT = 'product-list_search-result',
	PRODUCT_LIST_SEARCH_NO_RESULT_RCM = 'product-list_search-no-result-rcm',
	PRODUCT_CAMPAIGN_NAME = 'product-campaign:<campaignName>',
	QUICK_ORDER_DEFAULT_LIST = 'quick-order_default-list',
	QUICK_ORDER_SEARCH_RESULT = 'quick-order_search-result',
	SELLER_PAGE = 'seller-page',
	WISHLIST = 'wishlist',
	VIEWED_PAGE = 'viewed-page',
	JFY_PRODUCT_LIST_SKU_NOT_BUY_RECENTLY = 'just_for_you_product-list_SKU_NOT_BUY_RECENTLY',
	CART_SELLER_BLOCK = 'cart-page_seller:<sellerCode>'
}

export enum EnumTrackingStatic {
	DAILY_DEAL = 'daily-deal',
	MEGA_BLOCK = 'mega-block'
}
