import ProductCardNew from 'components-v2/mocules/ProductCardNew';
import SliderProductBlock from 'components/mocules/Skeleton/SliderProductBlock';
import ListProductWithTitle from 'components/mocules/ListProductWithTitle';
import { useGetProductsDisplayedFromAppSettings } from 'hooks/useGetProductsDisplayedFromAppSettings';
import { EnumProductsDisplayedMobile } from 'constants/Enums/app-settings';

const ProductSliderSection = ({
  products = [],
  name = '',
  viewMore = true,
  redirect = '',
  loading = false,
  productsType = '',
  icon,
  isMobile = false,
  viewMoreInTitle,
  forceShowButtonAtBottom,
  gap,
  backgroundImage,
}) => {
  // String 'HOME_CHEAPEST_PRODUCTS' from Web app Config => EnumAppSettings.PRODUCTS_DISPLAYED
  const MAXIMUM_PRODUCT_SHOW = useGetProductsDisplayedFromAppSettings(EnumProductsDisplayedMobile.HOME_CHEAPEST_PRODUCTS);

  return loading ? (
    <SliderProductBlock />
  ) : (
    <ListProductWithTitle
      name={name}
      viewMore={viewMore}
      viewMoreInTitle={viewMoreInTitle}
      redirect={redirect}
      loading={loading}
      productsType={productsType}
      icon={icon}
      isMobile={isMobile}
      gap={gap}
      forceShowButtonAtBottom={forceShowButtonAtBottom}
      backgroundImage={backgroundImage}
    >
      {products?.slice(0, MAXIMUM_PRODUCT_SHOW)?.map((product, index) => (
        <ProductCardNew product={product} key={`item-product-${product.slug}`} index={index} url={product?.defaultImage} />
      ))}
    </ListProductWithTitle>
  );
};

export default ProductSliderSection;
