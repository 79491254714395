import { PUT } from './Clients';

const CUSTOMER_API_PREFIX = '/marketplace/customer/v1';

const ratingOrder = async ({ body }) => {
  try {
    return await PUT({
      url: `${CUSTOMER_API_PREFIX}/me/rating`,
      body,
    });
  } catch (error) {
    return error?.message;
  }
};

export default {
  ratingOrder,
};

