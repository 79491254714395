import clsx from 'clsx';
import ProductCardNew from 'components-v2/mocules/ProductCardNew';
import ProductSliderSection from 'components/organisms/ProductSliderSection';
import { useCart } from 'context/Cart/CartContext';
import { useMemo, useState } from 'react';
import mapDataProductV2 from 'services/convertNewAPIProduct';
import styles from './styles.module.css';

const HomePageBlockV2 = ({
  type,
  redirectUrl,
  viewMore,
  viewMoreInTitle,
  name,
  icon,
  product,
  isMobile,
  isTourGuide,
  finishGuide,
  gap,
  forceShowButtonAtBottom,
  backgroundImage,
  blockCodeTracking = ""
}) => {
  const { mapDataProduct } = useCart();
  const [dataMapQuantity, setDataMapQuantity] = useState([]);

  useMemo(() => {
    const productRs = mapDataProductV2({ product });
    let newDataMapQuantity = [];
    if (productRs) {
      newDataMapQuantity = mapDataProduct(productRs);
    }
    if (isTourGuide) {
      newDataMapQuantity.sort((a, b) => b?.skuTags?.length - a?.skuTags?.length);
    }
    setDataMapQuantity([...newDataMapQuantity]);
  }, [product]);

	const dataWithTracking = dataMapQuantity.map(item => ({
		...item,
		blockCode: blockCodeTracking
	}))

  return (
    <div className={styles.containerPage}>
      {isTourGuide && (
        <div className={clsx(finishGuide && styles.displayNone)}>
          <div className={clsx(styles.productGuideContainer, type !== 'MEGA-SALE' && styles.productGuideNotMega)}>
            <div id="product__card_item">
              <ProductCardNew product={dataWithTracking[0]} url={dataMapQuantity[0]?.defaultImage} isTourGuide />
            </div>
          </div>
        </div>
      )}
      <ProductSliderSection
        name={name}
        viewMore={viewMore}
        viewMoreInTitle={viewMoreInTitle}
        products={dataWithTracking}
        redirect={redirectUrl}
        productsType={type}
        icon={icon}
        isMobile={isMobile}
        gap={gap}
        forceShowButtonAtBottom={forceShowButtonAtBottom}
        backgroundImage={backgroundImage}
      />
    </div>
  );
};

export default HomePageBlockV2;
